import axiosInstance from "./AxiosInstance";

export const DocumentsType =  (props) => {
    let url = '/documents/types';

    axiosInstance.get(url).then((response) => {
        // Handle successful response (status code 200)
        console.log(response.data.data)
        props.setDocumentType(response.data.data)

    }).catch((error) => {
        console.log(error)
        //  setIsLoading(false)
        if (error.response && error.response.status === 422) {
            // Handle the 422 response (validation error)
            props.setErrors(error.response.data.message)
            // setIsLoading(false)
            console.log('Validation Errors:', error.response.data.message);
        } else {
            // Handle other errors (e.g., network issues)
            props.setErrors(error)
            // setIsLoading(false)
            console.error('Error:', error);
        }
    })

}

export const addDocumentFetch = (props) => {
    let url = '/profile/' + props.id + '/upload'
    props.setIsLoading &&  props.setIsLoading(true)
    axiosInstance.post(url, props.formData, {
            'headers': {
                'Content-Type': 'multipart/form-data'
            },
            'params': props.addDocument,
        },
    ).then((response) => {
        // Handle successful response (status code 200)

        console.log('add document', response)
        props.setMessage(response.data.message)
        props.setIsLoading &&  props.setIsLoading(false)
        props.setUpdate &&  props.setUpdate(!props.update)
       /* setTimeout(function () {
            props.navigate('/comunicazioni')
        },1000)*/
    }).catch((error) => {
        console.log(error)
        props.setIsLoading && props.setIsLoading(false)
        if (error.response && error.response.status === 422) {
            // Handle the 422 response (validation error)
            props.setErrors && props.setErrors(error.response.data.errors);
            props.setGeneralError && props.setGeneralError(error.response.data.message)
            props.setIsLoading && props.setIsLoading(false)
            console.log('Validation Errors?:', error.response.data.errors);
        } else {
            // Handle other errors (e.g., network issues)
            props.setErrors && props.setErrors(error)
            props.setGeneralError && props.setGeneralError(error.response.data.message)
            props.setIsLoading && props.setIsLoading(false)
            console.error('Error:', error);
        }
    })
}

export const deleteDocument = (props) => {
    props.setIsLoading(true)

    let url = '/profile/' + props.id +'/documents/' + props.documentToDelete

    axiosInstance.delete(url).then((response) => {

        console.log('delete association', response)
        props.setMessage(response.data.message)
        props.setIsLoading(false)
        props.setUpdate(!props.update)
        props.setDocumentToDelete()
        props.setShowConfirm(false)
    }).catch((error) => {
        console.log(error)
        props.setIsLoading(false)
        if (error.response && error.response.status === 422) {
            // Handle the 422 response (validation error)
            props.setErrors &&  props.setErrors(error.response.data.message)
            props.setIsLoading(false)
            console.log('Validation Errors:', error.response.data.message);
        } else {
            // Handle other errors (e.g., network issues)
            props.setErrors &&  props.setErrors(error)
            props.setIsLoading(false)
            console.error('Error:', error);
        }
    })
}

export const downloadDocument = (props) => {
    let url = '/profile/' + props.id +'/documents/' + props.documentToDownload

    props.setIsLoading &&  props.setIsLoading(true)
    axiosInstance.get(url
    ).then((response) => {
        // Handle successful response (status code 200)

        console.log('downloadFile', response)
        console.log('downloadFile', response.data.data.link)
        // Apri il link in una nuova finestra
        if (response.data && response.data.data && response.data.data.link) {
            window.open(response.data.data.link, '_blank');
        }

        // props.setCommunicationDetail(response.data.data)
        props.setIsLoading &&  props.setIsLoading(false)
    }).catch((error) => {
        console.log(error)
        props.setIsLoading && props.setIsLoading(false)
        if (error.response && error.response.status === 422) {
            // Handle the 422 response (validation error)
            props.setErrors && props.setErrors(error.response.data.message)

            props.setIsLoading && props.setIsLoading(false)
            console.log('Validation Errors:', error.response.data.message);
        } else {
            // Handle other errors (e.g., network issues)
            props.setErrors && props.setErrors(error)
            props.setIsLoading && props.setIsLoading(false)
            console.error('Error:', error);
        }
    })
}